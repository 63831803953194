import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import { pageData } from "./data";
import Header from "./Header";


function App() {
  const [activeSubTopicIndex, setActiveSubTopicIndex] = useState(0)
  const [activeTopicLessonIndex, setActiveTopicLessonIndex] = useState(0)
  const [courseName, setCourseName] = useState("")

  useEffect(() => {
    // console.log(`activeSubTopicIndex changed to ${activeSubTopicIndex}`)
    setSubTopics(pageData.subtopics)
    setCourseName(pageData.topic)
    resetSubTopicLessons(activeSubTopicIndex,activeTopicLessonIndex)
  }, [activeSubTopicIndex, activeTopicLessonIndex])


  const [subtopics, setSubTopics] = useState(pageData.subtopics)
  const totalNumberOfSubTopics = subtopics.length + 1
  const [activeSubTopic, setActiveSubTopic] = useState({})
  
  const [activeLesson, setActiveLesson] = useState([])
  const [activeLessonName, setActiveLessonName] = useState("")
  const [activeLessonDescription, setActiveLessonDescription] = useState("")

  const [lessonContent, setLessonContent] = useState([])

  const handleActionLesson = (indexParam) => {
    const index = indexParam < 0 || indexParam >= activeSubTopic.lessons.length ? 0 : indexParam

    setActiveTopicLessonIndex(index)
    const lesson = activeSubTopic.lessons[index]
    setActiveLesson(lesson)
    setActiveLessonName(lesson.name)
    setActiveLessonDescription(lesson.description)

  }


  const resetSubTopicLessons = (index,activeTopicLessonIndex) => {
    const topic = subtopics[index]
    
    const { lessons } = topic

    // const activeTopicLessonIndex =0

    setActiveSubTopic(topic)
    setActiveTopicLessonIndex(() => activeTopicLessonIndex)
    setLessonContent([])
    if (typeof lessons !== "undefined" & lessons?.length > 0) {

      setActiveLesson(lessons[activeTopicLessonIndex])
      setActiveLessonName(lessons[activeTopicLessonIndex].name)
      setActiveLessonDescription(lessons[activeTopicLessonIndex].description)


      const content = lessons[activeTopicLessonIndex].content

      setLessonContent(content)

    }

  }


  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-2">
          <Sidebar setActiveTopicLessonIndex={setActiveTopicLessonIndex}  activeSubTopicIndex={activeSubTopicIndex} setActiveSubTopicIndex={setActiveSubTopicIndex} />
        </div>
        <div className="col-md-10">
          <Header courseName={courseName} activeSubTopicIndex={activeSubTopicIndex} totalNumberOfSubTopics={totalNumberOfSubTopics}
            activeSubTopic={activeSubTopic} activeLessonDescription={activeLessonDescription} activeLessonName={activeLessonName}
            activeTopicLessonIndex={activeTopicLessonIndex} handleActionLesson={handleActionLesson} />

          <div className="row">
            <div className="col-md-10">
              {lessonContent && lessonContent.length > 0 ? (
                lessonContent.map((item, index) => (
                  <div key={index} className="card mt-2">
                    <div className="card-body">
                      <h5 className="card-title">{item.title}</h5>

                      <p className="card-text" dangerouslySetInnerHTML={{ __html: item.description }} />
                    </div>
                  </div>
                ))
              ) : (
                <div className="alert alert-info mt-4">
                  Currently content available for this lesson.
                </div>
              )}
            </div>
            <div className="col-md-2">
              {
                lessonContent.length > 0 ? (<>
                  <h6 className="mt-3">On this page you will find</h6>
                  <ul className="list-group">
                    {lessonContent.map((item, index) => <li key={index} className={`list-group-item`}>{item.title}</li>)}
                  </ul>
                </>

                ) : (
                  <div className="alert alert-info mt-4">
                    Currently content available for this lesson.
                  </div>
                )
              }

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;