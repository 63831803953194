import React from 'react';
import { pageData } from "./data";

const portalUrl = process.env.REACT_APP_PORTAL_URL

function Sidebar({activeSubTopicIndex,setActiveSubTopicIndex, setActiveTopicLessonIndex}) {
    const { topic, subtopics } = pageData
    

    const handleClick = (index)=>{
        setActiveTopicLessonIndex(0)
        setActiveSubTopicIndex(index)
    }
    
    return (
        <div className="container" style={{ padding: "20px" }}>
            <a target="_blank" href={portalUrl} rel="noreferrer" >Go to Home</a>
            <h4 style={{ marginTop: "20px", marginBottom: "20px" }}>
                {topic}
            </h4>

            <h6>
                25% COMPLETE
            </h6>
            <div className="progress">
                <div className="progress-bar progress-bar-striped" role="progressbar" style={{ width: "10%" }} aria-valuenow="10"
                    aria-valuemin="0" aria-valuemax="100"></div>
            </div>

            <ul style={{ marginTop: "20px", marginBottom: "20px" }} className="list-group">
                {
                    subtopics.map((item, index) => (
                        <li className={` cursor-pointer list-group-item ${activeSubTopicIndex === index ? "list-group-item-info" : ""}`}
                            key={index} onClick={() => handleClick(index)}>{item.name}</li>
                    ))
                }
            </ul>
        </div>
    );
}

export default Sidebar;